import { Link } from 'react-router-dom';

const Notfound = () => {
    return (
        <div style={{marginTop: '100px'}}>
            <center>
            <h1 >404 - Not Found!</h1>
            <Link to="/">
            Go Home
            </Link>
            </center>
        </div>
    )
}

export default Notfound
