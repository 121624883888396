import logo from '../../img/logo_name.png';
import PropTypes from "prop-types";
import { BiCog } from 'react-icons/bi'
// import { Link } from 'react-router-dom'

const Header = ( {username, title} ) => {
    return (
        <div className="dashboardHeader">
            <div className="left">
                <img src={logo}  alt="logo"/>
                <div className="brand">{title}</div>
            </div>
            <div className="right">
                <div className="settings"><BiCog/></div>
                <div className="username">{username}</div>
            </div>
        </div>
    )
}

Header.defaultProps = {
    title: 'Trusted Travelers',
    username: 'TrustedUser01',
}

Header.propTypes = {
    title: PropTypes.string.isRequired,
}


export default Header
