import room1 from '../../img/room1.png'
import room2 from '../../img/room2.png'
import room3 from '../../img/room3.png'
import room5 from '../../img/room5.png'
import {AiOutlineClose} from 'react-icons/ai'
import {AiOutlineCheck} from 'react-icons/ai'

const Private = () => {
    return (
        <div className="dashboardContainer">
            <div className="score">
                <div className="container">
                    <div className="box_all">
                        <p>Overall Score:</p>
                        <p className="scoreNumber">8/10</p>
                    </div>

                    <div className="box_300">
                        <p>Preparation:</p>
                        <p className="scoreNumber">5/10</p>
                    </div>
                    <div className="box_300">
                        <p>Check In:</p>
                        <p className="scoreNumber">8/10</p>
                    </div>
                    <div className="box_300">
                        <p>Friendliness:</p>
                        <p className="scoreNumber">10/10</p>
                    </div>
                    <div className="box_300">
                        <p>Noise:</p>
                        <p className="scoreNumber">9/10</p>
                    </div>
                    <div className="box_300">
                        <p>Cleanliness:</p>
                        <p className="scoreNumber">7/10</p>
                    </div>
                    <div className="box_300">
                        <p>Damages:</p>
                        <p className="scoreNumber">10/10</p>
                    </div>
                    <div className="box_300">
                        <p>Smoke Free:</p>
                        <p className="scoreNumber">10/10</p>
                    </div>
                    <div className="box_300">
                        <p>Linens:</p>
                        <p className="scoreNumber">8/10</p>
                    </div>
                    <div className="box_300">
                        <p>Organization:</p>
                        <p className="scoreNumber">10/10</p>
                    </div>
                    <div className="box_300">
                        <p>Check Out Time:</p>
                        <p className="scoreNumber">10/10</p>
                    </div>
                </div>
            </div>      

            <div class="premium">
                <div className="premuimBenefitsHeader">
                    Premium Benefits
                </div>
                <table className="styled-table">
                    <thead>
                        <tr>
                            <th className="w55"></th>
                            <th className="w15">Basic</th>
                            <th className="w15">Regular</th>
                            <th className="w15">Premium</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="w55">Monthly cost</td>
                            <td className="w15">Free</td>
                            <td className="w15">$8.99</td>
                            <td className="w15">$17.99</td>
                        </tr>
                        <tr>
                            <td className="w55">See Score</td>
                            <td className="green w15"><AiOutlineCheck/></td>
                            <td className="green w15"><AiOutlineCheck/></td>
                            <td className="green w15"><AiOutlineCheck/></td>
                        </tr>
                        <tr>
                            <td className="w55">See Feedback</td>
                            <td className="red w15"><AiOutlineClose /></td>
                            <td className="green w15"><AiOutlineCheck/></td>
                            <td className="green w15"><AiOutlineCheck/></td>
                        </tr>
                        <tr>
                            <td className="w55">Special Discount</td>
                            <td className="red w15"><AiOutlineClose /></td>
                            <td className="red w15"><AiOutlineClose /></td>
                            <td className="green w15"><AiOutlineCheck/></td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className="benefits">
                <div className="benefitsHeader">
                    Benefits
                </div>
                <div className="boxContainer">
                    <div className="box_250">
                        <img src={room1} alt="room1"/>
                        <span>15% Off</span>
                    </div>
                    <div className="box_250">
                        <img src={room2} alt="room2" />
                        <span>1 Night free</span>
                    </div>
                    <div className="box_250">
                        <img src={room3} alt="room3" />
                        <span>10% off</span>
                    </div>
                    <div className="box_250">
                        <img src={room5} alt="room5" />
                        <span>15% off</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Private

