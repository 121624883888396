// import { useState, useEffect } from 'react'
// import { Link } from 'react-router-dom'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Header from './components/header'
import Login from './components/login/'
import Footer from './components/footer'
import LoginBusiness from './components/login/business'
import LoginPrivate from './components/login/private'
import DashboardHeader from './components/dashboard/header'
import DashboardBusiness from './components/dashboard/business'
import DashboardPrivate from './components/dashboard/private'
import Slide from './components/dashboard/slide'
import NotFound from "./components/notfound"

function App() {

  return (
    <Router>
      <Switch>
        <Route path="/" exact render={(props) => (
          <div className="dimScreen" >
            <div className="container">
              <Header />
              <Login />
              <Footer />
            </div>
          </div>
        )} />

        <Route path="/private" exact render={(props) => (
          <div className="container">
            <div className="dimScreen" >
              <Header />
              <LoginPrivate />
              <Footer />
            </div>
          </div>
        )} />

        <Route path="/private/dashboard" exact render={(props) => (
          <div className="container">
            <Slide />   
            <DashboardHeader />
            <DashboardPrivate />
          </div>
        )} />
        
        <Route path="/business" exact render={(props) => (
          <div className="dimScreen" >
            <div className="container">
              <Header />
              <LoginBusiness />
              <Footer />
            </div>
          </div>
        )} />

        <Route path="/business/dashboard" exact render={(props) => (
          <div className="container">
            <Slide />
            <DashboardHeader />
            <DashboardBusiness />
          </div>
        )} />

        <Route path="*" component={NotFound} />
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
