import people from "../../../src/img/people.png"
import briefcase from "../../../src/img/briefcase.png"
import { Link } from 'react-router-dom'

const index = ( ) => {
    return (
        <div className="login">
            <div className="business">
            <Link to="/business">
                <img src={briefcase} alt="briefcase"/>
                <p>Login for business</p>
            </Link>
            </div>
            <div className="private">
            <Link to="/private">
                <img src={people} alt="people" />
                <p>Login for private</p>
            </Link>
            </div>
        </div>
    )
}

export default index
