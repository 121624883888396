const Slide = () => {
    return (
        <div class="advertisment">
            <div className="text">
                20% off for Premium Members
            </div>
        </div>
    )
}

export default Slide
