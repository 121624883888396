import PropTypes from "prop-types";
import logo from '../img/logo_name.png'
import { Link } from 'react-router-dom'

const Header = ({title, footnote}) => {
    return (
        <Link to="/">
        <div className="header">
            <img src={logo} alt="Logo"/>
            <p>{title}</p>
            <div className="footnote">{footnote}</div>
        </div>
        </Link>
    )
}


Header.defaultProps = {
    title: 'Hotels - AirBnB - Car Rental',
    footnote: 'For trusted travelers worldwide',
}

Header.propTypes = {
    title: PropTypes.string.isRequired,
}

export default Header
