import { Link } from 'react-router-dom'
import people from "../../../src/img/people.png"

const Private = () => {
    return (
        <form className='business-login-form'>
            <div className='form-control'>
            <img src={people}  alt="people" />
                <input type='text'  placeholder="Username"/>
            </div> 
            <div className='form-control' >
                <input type='password' placeholder="Password"/>
            </div> 
            
            {/* <input type='submit' value='Login' className='btn btn-block' style={{backgroundColor: '#1b5f46'}} /> */}
            <Link to="/private/dashboard" className='btn btn-block' style={{backgroundColor: '#1b5f46'}} >
               <center>Login</center>
            </Link>
            <Link to="/" className='btn btn-block' style={{backgroundColor: '#50967c'}} >
               <center>Back</center>
            </Link>
        </form>
    )
}

export default Private
